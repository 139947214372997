import moment from 'moment';

import _ from 'lodash';

export function StringToDate(value){
    if(value === null){
        return 'No disponible';
    }
    return moment(String(value)).format('DD/MM/YYYY');
}

export function number (value) {
    return _.floor(value, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}
