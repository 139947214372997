import Vue from 'vue';
import Vuex from 'vuex';

/**
 *  Para mantener el estado de vuex-states de modulos especificos.
 */
import createPersistedState from  'vuex-persistedstate';

Vue.use(Vuex);

import configuration        from  './modules/configuration.js';
import notifications        from  './modules/notifications.js';
import authentication       from  './modules/authentication.js';
import abilities            from  './modules/abilities.js';
import files                from  './modules/files.js';
import maps                 from  './modules/maps.js';
import user                 from  './modules/user.js';
import roles                 from  './modules/roles.js';
import clients               from  './modules/clients.js';
import client_types          from  './modules/client_types.js';
import document_types        from  './modules/document_types.js';
import documents        from  './modules/documents.js';
import settings              from  './modules/settings.js';
import currencies            from  './modules/currencies.js';
import templates             from  './modules/templates.js';
import ivas                  from  './modules/ivas.js';
import service_types         from  './modules/service_types.js';
import business_unities      from  './modules/business_unities.js';
import services              from  './modules/services.js';
import areas                 from  './modules/areas.js';
import report_types          from  './modules/report_types.js';
import vacation_settings     from  './modules/vacation_settings.js';
import employees             from  './modules/employees.js';
import employee_vacations    from  './modules/employee_vacations.js';
import employee_reports      from  './modules/employee_reports.js';

const dataState = createPersistedState({
  paths: ['configuration']
})

export default new Vuex.Store({
  namespace: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configuration,
    notifications,
    authentication,
    abilities,
    files,
    maps,
    user,
    roles,
    clients,
    client_types,
    document_types,
    documents,
    settings,
    currencies,
    templates,
    ivas,
    service_types,
    business_unities,
    services,
    areas,
    report_types,
    vacation_settings,
    employees,
    employee_vacations,
    employee_reports,
  },
  plugins: [dataState]
})