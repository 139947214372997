const namespaced = true

import api from '../../api/areas.js';
import _ from 'lodash';

const state = {
    areas : [],
}
const mutations = {
     MUTATION_SET_AREAS(state, areas) {
          state.areas = areas;
     },
     MUTATION_ADD_AREA(state, area){
          state.areas.push(area);
     },
     MUTATION_UPDATE_AREA(state, areaUpdate){
          let areas = state.areas;
          state.areas = [];
          _.forEach(areas, area => {
               state.areas.push((area.id == areaUpdate.id)?areaUpdate:area);
          });
     },
     MUTATION_DELETE_AREA(state, areaId){
          let areas = state.areas;
          state.areas = [];
          _.forEach(areas, area => {
               if(area.id != areaId){
                    state.areas.push(area);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_AREAS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_AREA', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_AREA', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_AREA', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
