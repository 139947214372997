const namespaced = true

import api from '../../api/client_types.js';
import _ from 'lodash';

const state = {
    client_types : [],
}
const mutations = {
     MUTATION_SET_CLIENT_TYPES(state, client_types) {
          state.client_types = client_types;
     },
     MUTATION_ADD_CLIENT_TYPE(state, client_type){
          state.client_types.push(client_type);
     },
     MUTATION_UPDATE_CLIENT_TYPE(state, clientTypeUpdate){
          let client_types = state.client_types;
          state.client_types = [];
          _.forEach(client_types, client => {
               state.client_types.push((client.id == clientTypeUpdate.id)?clientTypeUpdate:client);
          });
     },
     MUTATION_DELETE_CLIENT_TYPE(state, userId){
          let client_types = state.client_types;
          state.client_types = [];
          _.forEach(client_types, client_type => {
               if(client_type.id != userId){
                    state.client_types.push(client_type);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_CLIENT_TYPES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_CLIENT_TYPE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_CLIENT_TYPE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_CLIENT_TYPE', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
