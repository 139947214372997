const namespaced = true

import api from '../../api/settings.js';
import _ from 'lodash';

const state = {
    settings : [],
}
const mutations = {
     MUTATION_SET_SETTINGS(state, settings) {
          state.settings = settings;
     },
     MUTATION_ADD_SETTING(state, setting){
          state.settings.push(setting);
     },
     MUTATION_UPDATE_SETTING(state, settingUpdate){
          let settings = state.settings;
          state.settings = [];
          _.forEach(settings, setting => {
               state.settings.push((setting.id == settingUpdate.id)?settingUpdate:setting);
          });
     },
     MUTATION_DELETE_SETTING(state, settingId){
          let settings = state.settings;
          state.settings = [];
          _.forEach(settings, setting => {
               if(setting.id != settingId){
                    state.settings.push(setting);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_SETTINGS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_SETTING', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_SETTING', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_SETTING', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
