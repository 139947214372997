const namespaced = true

import api from '../../api/employee_vacations.js';
import _ from 'lodash';

const state = {
    employee_vacations : [],
}
const mutations = {
     MUTATION_SET_EMPLOYEE_VACATIONS(state, employee_vacations) {
          state.employee_vacations = employee_vacations;
     },
     MUTATION_ADD_EMPLOYEE_VACATION(state, employeeVacation){
          state.employee_vacations.push(employeeVacation);
     },
     MUTATION_UPDATE_EMPLOYEE_VACATION(state, employeeVacationUpdate){
          let employee_vacations = state.employee_vacations;
          state.employee_vacations = [];
          _.forEach(employee_vacations, employeeVacation => {
               state.employee_vacations.push((employeeVacation.id == employeeVacationUpdate.id)?employeeVacationUpdate:employeeVacation);
          });
     },
     MUTATION_DELETE_EMPLOYEE_VACATION(state, employeeVacationId){
          let employee_vacations = state.employee_vacations;
          state.employee_vacations = [];
          _.forEach(employee_vacations, employeeVacation => {
               if(employeeVacation.id != employeeVacationId){
                    state.employee_vacations.push(employeeVacation);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_EMPLOYEE_VACATIONS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_EMPLOYEE_VACATION', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_EMPLOYEE_VACATION', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_EMPLOYEE_VACATION', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
