const namespaced = true

import api from '../../api/ivas.js';
import _ from 'lodash';

const state = {
    ivas : [],
}
const mutations = {
     MUTATION_SET_IVAS(state, ivas) {
          state.ivas = ivas;
     },
     MUTATION_ADD_IVA(state, iva){
          state.ivas.push(iva);
     },
     MUTATION_UPDATE_IVA(state, ivaUpdate){
          let ivas = state.ivas;
          state.ivas = [];
          _.forEach(ivas, iva => {
               state.ivas.push((iva.id == ivaUpdate.id)?ivaUpdate:iva);
          });
     },
     MUTATION_DELETE_IVA(state, userId){
          let ivas = state.ivas;
          state.ivas = [];
          _.forEach(ivas, iva => {
               if(iva.id != userId){
                    state.ivas.push(iva);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_IVAS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_IVA', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_IVA', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_IVA', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
