import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary:    "#42A5F5",
                secondary:  "#ff6400",
                accent:     "#01579B", // Revisado
                error:      "#B71C1C", // Revisado
                purple:     "#4A148C", // Revisado
                warning:    "#ff9800",
                info:       "#2196f3",
                success:    "#4caf50",
                selected :  "#C8E6C9"
            },
            dark: {
                primary:    '#0D47A1',
                secondary:  "#c43100",
                accent:     "#4FC3F7", // Revisado
                purple:     "#CE93D8", // Revisado
                error:      "#E57373", // Revisado
                warning:    "#E65100",
                info:       "#0D47A1",
                success:    "#1B5E20",
                selected :  "#1B5E20"
            },
        },
    },
}

export default new Vuetify(opts)