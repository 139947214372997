import Vue from 'vue';  
import Router from 'vue-router'

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: 'Inicio'
    }
  },
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/logout',
    name: 'logout',
  },
  {
    path: '/register',
    name: 'register',
  },
  // {
  //   path: '/documents/calendar',
  //   name: 'documents/calendar',
  //   meta: {
  //     title: 'Calendario',
  //   },
  // },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      title: 'Administración / Configuraciones',
    },
  },
  {
    path: '/users-manager',
    name: 'users',
    meta: {
      title: 'Administración / Gestión de Usuarios',
    },
  },
  {
    path: '/documents-manager',
    name: 'documents',
    meta: {
      title: 'Administración / Facturación',
    },
  },
  {
    path: '/services-manager',
    name: 'services',
    meta: {
      title: 'Administración / Servicios',
    },
  },
  {
    path: '/rh/management',
    name: 'rh/management',
    meta: {
      title: 'Recursos Humanos / Gestión',
    },
  },
  {
    path: '/rh/employees',
    name: 'rh/employees',
    meta: {
      title: 'Recursos Humanos / Empleados',
    },
  },
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  // mode: 'history',
  base: __dirname,
  routes
})

export default router