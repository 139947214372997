const namespaced = true

import api from '../../api/business_unities.js';
import _ from 'lodash';

const state = {
    business_unities : [],
}
const mutations = {
     MUTATION_SET_BUSINESS_UNITIES(state, business_unities) {
          state.business_unities = business_unities;
     },
     MUTATION_ADD_BUSINESS_UNITY(state, businessUnity){
          state.business_unities.push(businessUnity);
     },
     MUTATION_UPDATE_BUSINESS_UNITY(state, businessUnityUpdate){
          let business_unities = state.business_unities;
          state.business_unities = [];
          _.forEach(business_unities, businessUnity => {
               state.business_unities.push((businessUnity.id == businessUnityUpdate.id)?businessUnityUpdate:businessUnity);
          });
     },
     MUTATION_DELETE_BUSINESS_UNITY(state, userId){
          let business_unities = state.business_unities;
          state.business_unities = [];
          _.forEach(business_unities, businessUnity => {
               if(businessUnity.id != userId){
                    state.business_unities.push(businessUnity);
               }
          });
     },
     MUTATION_ADD_CLIENT(state, businessUnity){
          state.business_unities = _.map(state.business_unities, busiUnity => {
               if(busiUnity.id == busiUnity.id){
                    busiUnity.clients = businessUnity.clients;
               }
               return busiUnity;
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_BUSINESS_UNITIES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_BUSINESS_UNITY', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_BUSINESS_UNITY', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_BUSINESS_UNITY', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     addClient(context, {business_unity_id, client_id}){
          return new Promise((resolve, reject) => {
               api.addClient(business_unity_id, client_id).then(resp => {
                    context.commit('MUTATION_ADD_CLIENT', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
