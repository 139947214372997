const namespaced = true

import api from '../../api/services.js';
import _ from 'lodash';

const state = {
     services : [],
     clientSelected: {
          services: [],
     },
}
const mutations = {
     MUTATION_SET_SERVICES(state, services) {
          state.services = services;
     },
     MUTATION_ADD_SERVICE(state, service){
          state.services.push(service);
          state.clientSelected.services.push(service);
     },
     MUTATION_UPDATE_SERVICE(state, serviceUpdate){
          let services = state.services;
          state.services = [];
          _.forEach(services, service => {
               state.services.push((service.id == serviceUpdate.id)?serviceUpdate:service);
          });
     },
     MUTATION_UPDATE_SERVICE_OF_CLIENT(state, service){
          let services = state.clientSelected.services;
          state.clientSelected.services = [];
          _.forEach(services, serv => {
               state.clientSelected.services.push((serv.id == service.id)?service:serv);
          });
     },
     MUTATION_DELETE_SERVICE(state, userId){
          let services = state.services;
          state.services = [];
          _.forEach(services, service => {
               if(service.id != userId){
                    state.services.push(service);
               }
          });
     },
     MUTATION_SET_CLIENT(state, client){
          state.clientSelected = client;
     },
     MUTATION_CLOSE_SERVICE(state, serv){
          state.clientSelected.services = _.filter(state.clientSelected.services, service => { return service.id != serv.id; });
          state.clientSelected.services.push(serv);
          state.services = _.filter(state.services, service => { return service.id != serv.id; });
          state.services.push(serv);
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_SERVICES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_SERVICE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_SERVICE', resp.data);
                    context.commit('MUTATION_UPDATE_SERVICE_OF_CLIENT', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_SERVICE', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     selectClient(context, client){
          context.commit('MUTATION_SET_CLIENT', client);
     },
     unSelectClient(context){
          context.commit('MUTATION_SET_CLIENT', {});
     },
     close(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.close(id, data).then(resp => {
                    context.commit('MUTATION_CLOSE_SERVICE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
