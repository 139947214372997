const namespaced = true

import api from '../../api/currencies.js';
import _ from 'lodash';

const state = {
    currencies : [],
}
const mutations = {
     MUTATION_SET_CURRENCIES(state, currencies) {
          state.currencies = currencies;
     },
     MUTATION_ADD_CURRENCY(state, currency){
          state.currencies.push(currency);
     },
     MUTATION_UPDATE_CURRENCY(state, currencyUpdate){
          let currencies = state.currencies;
          state.currencies = [];
          _.forEach(currencies, currency => {
               state.currencies.push((currency.id == currencyUpdate.id)?currencyUpdate:currency);
          });
     },
     MUTATION_DELETE_CURRENCY(state, userId){
          let currencies = state.currencies;
          state.currencies = [];
          _.forEach(currencies, currency => {
               if(currency.id != userId){
                    state.currencies.push(currency);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_CURRENCIES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_CURRENCY', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_CURRENCY', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_CURRENCY', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
