let prefix = '/api/business-unities';

function index() {
    return window.axios.get(prefix+'/index');
}
function store(data) {
    return window.axios.post(prefix+'/store', data)
}
function update(id, data) {
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function deleted(id){
    return window.axios.post(prefix+'/'+id+'/delete');
}
function addClient(business_unity_id, client_id){
    return window.axios.post(prefix+'/'+business_unity_id+'/'+client_id+'/add');
}
export default {
    index,
    store,
    update,
    deleted,
    addClient,
}