const namespaced = true

import api from '../../api/templates.js';
import _ from 'lodash';

const state = {
    templates : [],
}
const mutations = {
     MUTATION_SET_TEMPLATES(state, templates) {
          state.templates = templates;
     },
     MUTATION_ADD_TEMPLATE(state, template){
          state.templates.push(template);
     },
     MUTATION_UPDATE_TEMPLATE(state, templateUpdate){
          let templates = state.templates;
          state.templates = [];
          _.forEach(templates, template => {
               state.templates.push((template.id == templateUpdate.id)?templateUpdate:template);
          });
     },
     MUTATION_DELETE_TEMPLATE(state, userId){
          let templates = state.templates;
          state.templates = [];
          _.forEach(templates, template => {
               if(template.id != userId){
                    state.templates.push(template);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_TEMPLATES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_TEMPLATE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_TEMPLATE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_TEMPLATE', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
