const namespaced = true

import api from '../../api/documents';
import _ from 'lodash';

const state = {
     documents : [],
     documents_by_client: [],
}
const mutations = {
     MUTATION_SET_DOCUMENTS(state, documents) {
          state.documents = documents;
     },
     MUTATION_SET_DOCUMENTS_BY_CLIENT(state, documents_by_client) {
          state.documents_by_client = documents_by_client;
     },
     MUTATION_ADD_DOCUMENT(state, document){
          state.documents.push(document);
     },
     MUTATION_ADD_DOCUMENT_BY_CLIENT(state, document){
          state.documents_by_client.push(document);
     },
     MUTATION_UPDATE_DOCUMENT(state, documentUpdate){
          let documents = state.documents;
          state.documents = [];
          _.forEach(documents, document => {
               state.documents.push((document.id == documentUpdate.id)?documentUpdate:document);
          });
     },
     MUTATION_UPDATE_DOCUMENT_BY_CLIENT(state, documentUpdate){
          let documents_by_client = state.documents_by_client;
          state.documents_by_client = [];
          _.forEach(documents_by_client, document => {
               state.documents_by_client.push((document.id == documentUpdate.id)?documentUpdate:document);
          });
     },
     MUTATION_DELETE_DOCUMENT(state, documentId){
          let documents = state.documents;
          state.documents = [];
          _.forEach(documents, document => {
               if(document.id != documentId){
                    state.documents.push(document);
               }
          });
     },
     MUTATION_DELETE_DOCUMENT_BY_CLIENT(state, documentId){
          let documents_by_client = state.documents_by_client;
          state.documents_by_client = [];
          _.forEach(documents_by_client, document => {
               if(document.id != documentId){
                    state.documents_by_client.push(document);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_DOCUMENTS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     getByDates(context, data) {
          return new Promise((resolve, reject) => { 
               api.getByDates(data.date1, data.date2).then(function (res) {
                    context.commit('MUTATION_SET_DOCUMENTS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     getDocumentsByClient(context, id) {
          return new Promise((resolve, reject) => { 
               api.getDocumentsByClient(id).then(function (res) {
                    context.commit('MUTATION_SET_DOCUMENTS_BY_CLIENT', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_DOCUMENT', resp.data);
                    context.commit('MUTATION_ADD_DOCUMENT_BY_CLIENT', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_DOCUMENT', resp.data);
                    context.commit('MUTATION_UPDATE_DOCUMENT_BY_CLIENT', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_DOCUMENT', id);
                    context.commit('MUTATION_DELETE_DOCUMENT_BY_CLIENT', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     payment(context, id){
          return new Promise((resolve, reject) => {
               api.payment(id).then(resp => {
                    context.commit('MUTATION_UPDATE_DOCUMENT', resp.data);
                    context.commit('MUTATION_UPDATE_DOCUMENT_BY_CLIENT', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     sendEmail(context, id){
          return new Promise((resolve, reject) => {
               api.sendEmail(id).then(resp => {
                    context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data}, {root: true });
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
