let prefix = '/api/documents';

function index() {
    return window.axios.get(prefix+'/index');
}
function getByDates(date1, date2) {
    return window.axios.get(prefix+'/getByDates?date1='+date1+'&date2='+date2);
}
function getDocumentsByClient(id) {
    return window.axios.get(prefix+'/'+id+'/by-client');
}
function store(data) {
    return window.axios.post(prefix+'/store', data, { header: { 'Content-Type': 'file/*' } });
}
function update(id, data) {
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function deleted(id){
    return window.axios.post(prefix+'/'+id+'/delete');
}
function payment(id){
    return window.axios.post(prefix+'/'+id+'/payment');
}
function sendEmail(id){
    return window.axios.post(prefix+'/'+id+'/send-email');
}
export default {
    index,
    getByDates,
    getDocumentsByClient,
    store,
    update,
    deleted,
    payment,
    sendEmail,
}