const namespaced = true

import api from '../../api/clients.js';
import _ from 'lodash';

const state = {
    clients : [],
}
const mutations = {
     MUTATION_SET_CLIENTS(state, clients) {
          state.clients = clients;
     },
     MUTATION_ADD_CLIENT(state, client){
          state.clients.push(client);
     },
     MUTATION_UPDATE_CLIENT(state, clientUpdate){
          let clients = state.clients;
          state.clients = [];
          _.forEach(clients, client => {
               state.clients.push((client.id == clientUpdate.id)?clientUpdate:client);
          });
     },
     MUTATION_DELETE_CLIENT(state, userId){
          let clients = state.clients;
          state.clients = [];
          _.forEach(clients, client => {
               if(client.id != userId){
                    state.clients.push(client);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_CLIENTS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_CLIENT', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_CLIENT', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_CLIENT', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
