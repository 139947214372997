// import * as firebase from 'firebase';
// import "firebase/auth";

import { isNull } from 'lodash';
import api from '../../api/authentication.js';
import router from '../../routes/index.js';

const namespaced = true

const defaultUrl = '/home';

const state = {
    iaccept: false,
    isLoggedIn: false, 
    userAuth: {
        name: '',
        email: '',
        photoUrl: null, 
        emailVerified: false,
        uid: ''
    },
    showLogoutDialog : false,
    providerParameters : {
        prompt : 'select_account'
    }
}

const mutations = {
    MUTATION_SET_USER(state, value) {
        state.userAuth = value;
    },
    MUTATION_SET_LOGOUT(state, value) {
        state.showLogoutDialog = value;
    },
    SET_LOGINSTATUS(state, input_data){
        state.isLoggedIn = input_data;
    },
}

const actions = {
    login_server(context, data) {
        return new Promise((resolve) => { 
            api.login_server(data).then(resp => {
                // context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': resp.data.message }, { root: true });
                context.dispatch('configuration/setUserAuth',resp.data, {root: true });
                context.dispatch('redirectAfterLogin', resp.data);
                resolve(resp);
            }).catch(error => {
                // console.log(error);
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
            })
        })        
   },
   redirectAfterLogin(data){
       if(!isNull(data.id)){
           router.push(defaultUrl);
        }
   },
   redirectAfterLogout(context){
        context.dispatch('configuration/setUserAuth',{ id: 0, abilities: []},{root: true });
        router.push('/login');
   },
   logout(context){
        api.logout().then(resp => {
            context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': resp.data.message}, {root: true });
            context.dispatch('redirectAfterLogout', resp.data);
        }).catch(() => {
            context.dispatch('redirectAfterLogout', { 'code': false });
        });
   },

}

const getters = {

}


export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}