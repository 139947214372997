import _ from 'lodash';

const namespaced = true

const state = {
    themeDark: false,
    userAuth: {
        id: 0,
        name: '',
        email: null, 
        abilities:[],
        roles :[],
        slp_code:''
    },
    isLoggedIn: false,
}

const mutations = {
    SET_CONFIGURATION(state, input_data) {
        state = input_data;
    },
    SET_THEME_MODE(state, input_data) {
        state.themeDark = input_data;
    },
    SET_CONFIGURATION_USER_AUTH(state , value){
        state.userAuth = value;
        state.isLoggedIn = (value.id)?true:false;
    },
}

const actions = {
    resetConfiguration(context){
        context.commit('SET_CONFIGURATION', {
            themeDark: false,
            userAuth: {
                id: 0,
                name: '',
                email: null, 
                abilities:[],
                roles :[],
                slp_code:''
            },
            isLoggedIn: false
        });
    },
    setDarkMode(context, value) {
        if (value) {
            context.commit('SET_THEME_MODE', false);
        } else {
            context.commit('SET_THEME_MODE', true);
        }
    },
    setUserAuth(context,value){
        context.commit('SET_CONFIGURATION_USER_AUTH', value);
    }
    
}

const getters = {
    getConfigurationID(state){
        return state.userAuth.id
    },
    getConfigurationName(state){
        return state.userAuth.name
    },
    getConfigurationEmail(state){
        return state.userAuth.email
    },
    getAbilitiesUser(state){
        return state.userAuth.abilities;
    },
    getStringRolesUser(state){
       return _.toString(_.map(state.userAuth.roles, role => role.title));
    },
}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}