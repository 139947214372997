import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  computed: {
      ...mapState('abilities', ['all_abilities']),
  },
  methods: {
      $can(permissionName) {

        if(this.all_abilities.length <= 0){
          return false;
        }

        return _.findIndex(this.all_abilities, { 'name': permissionName, 'forbidden': false }) >= 0;
    },
  },
};