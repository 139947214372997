const namespaced = true

import api from '../../api/report_types.js';
import _ from 'lodash';

const state = {
    report_types : [],
}
const mutations = {
     MUTATION_SET_REPORT_TYPES(state, report_types) {
          state.report_types = report_types;
     },
     MUTATION_ADD_REPORT_TYPE(state, reportType){
          state.report_types.push(reportType);
     },
     MUTATION_UPDATE_REPORT_TYPE(state, reportTypeUpdate){
          let report_types = state.report_types;
          state.report_types = [];
          _.forEach(report_types, reportType => {
               state.report_types.push((reportType.id == reportTypeUpdate.id)?reportTypeUpdate:reportType);
          });
     },
     MUTATION_DELETE_REPORT_TYPE(state, reportTypeId){
          let report_types = state.report_types;
          state.report_types = [];
          _.forEach(report_types, reportType => {
               if(reportType.id != reportTypeId){
                    state.report_types.push(reportType);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_REPORT_TYPES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_REPORT_TYPE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_REPORT_TYPE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_REPORT_TYPE', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
