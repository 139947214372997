const namespaced = true

import api from '../../api/document_types';
import _ from 'lodash';

const state = {
     document_types : [],
}
const mutations = {
     MUTATION_SET_DOCUMENT_TYPES(state, document_types) {
          state.document_types = document_types;
     },
     MUTATION_ADD_DOCUMENT_TYPE(state, document_type){
          state.document_types.push(document_type);
     },
     MUTATION_UPDATE_DOCUMENT_TYPE(state, documentTypeUpdate){
          let document_types = state.document_types;
          state.document_types = [];
          _.forEach(document_types, document_type => {
               state.document_types.push((document_type.id == documentTypeUpdate.id)?documentTypeUpdate:document_type);
          });
     },
     MUTATION_DELETE_DOCUMENT_TYPE(state, documentTypeId){
          let document_types = state.document_types;
          state.document_types = [];
          _.forEach(document_types, document_type => {
               if(document_type.id != documentTypeId){
                    state.document_types.push(document_type);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_DOCUMENT_TYPES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_DOCUMENT_TYPE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_DOCUMENT_TYPE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_DOCUMENT_TYPE', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
