const namespaced = true

import api from '../../api/employees.js';
import _ from 'lodash';

const state = {
    employees : [],
}
const mutations = {
     MUTATION_SET_EMPLOYEES(state, employees) {
          state.employees = employees;
     },
     MUTATION_ADD_EMPLOYEE(state, employee){
          state.employees.push(employee);
     },
     MUTATION_UPDATE_EMPLOYEE(state, employeeUpdate){
          let employees = state.employees;
          state.employees = [];
          _.forEach(employees, employee => {
               state.employees.push((employee.id == employeeUpdate.id)?employeeUpdate:employee);
          });
     },
     MUTATION_DELETE_EMPLOYEE(state, employeeId){
          let employees = state.employees;
          state.employees = [];
          _.forEach(employees, employee => {
               if(employee.id != employeeId){
                    state.employees.push(employee);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_EMPLOYEES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_EMPLOYEE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_EMPLOYEE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_UPDATE_EMPLOYEE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     active(context, id){
          return new Promise((resolve, reject) => {
               api.active(id).then(resp => {
                    context.commit('MUTATION_UPDATE_EMPLOYEE', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     updateEmployee(context, employee){
          context.commit('MUTATION_UPDATE_EMPLOYEE', employee);
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
