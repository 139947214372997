const namespaced = true

import api from '../../api/vacation_settings.js';
import _ from 'lodash';

const state = {
    vacation_settings : [],
}
const mutations = {
     MUTATION_SET_VACATION_SETTINGS(state, vacation_settings) {
          state.vacation_settings = vacation_settings;
     },
     MUTATION_ADD_VACATION_SETTING(state, vacationSetting){
          state.vacation_settings.push(vacationSetting);
     },
     MUTATION_UPDATE_VACATION_SETTING(state, vacationSettingUpdate){
          let vacation_settings = state.vacation_settings;
          state.vacation_settings = [];
          _.forEach(vacation_settings, vacationSetting => {
               state.vacation_settings.push((vacationSetting.id == vacationSettingUpdate.id)?vacationSettingUpdate:vacationSetting);
          });
     },
     MUTATION_DELETE_VACATION_SETTING(state, vacationSettingId){
          let vacation_settings = state.vacation_settings;
          state.vacation_settings = [];
          _.forEach(vacation_settings, vacationSetting => {
               if(vacationSetting.id != vacationSettingId){
                    state.vacation_settings.push(vacationSetting);
               }
          });
     },
    
}
const actions = {
      // Name Property
     index(context) {
          return new Promise((resolve, reject) => { 
               api.index().then(function (res) {
                    context.commit('MUTATION_SET_VACATION_SETTINGS', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_VACATION_SETTING', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_VACATION_SETTING', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_VACATION_SETTING', id);
                    resolve(resp.data);
               }).catch(error => {
                    context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': error}, {root: true });
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
